import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import "./App.css";
import MainPage from "./MainPage";
import { RenderPage } from "./RenderPage";

const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={MainPage} />
      <Route path="/render/:documentId" component={RenderPage} />
      <Redirect to="/" />
    </Switch>
  );
};

export default App;

// const { documentId } = useParams();
// var data = localStorage.getItem(documentId);

// if (data) {
//   return <div dangerouslySetInnerHTML={{ __html: atob(data) }} />;
// }

// return <div>Not Found</div>;
