import React, { useState, useEffect } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Select,
  Upload,
  Button,
  Form,
  Tooltip,
  notification,
  Divider,
  Table,
  Space,
} from "antd";
import {
  UploadOutlined,
  InboxOutlined,
  DownloadOutlined,
  FileOutlined,
} from "@ant-design/icons";
import "./App.css";
import { v4 as uuidv4 } from "uuid";
import { Base64 } from "js-base64";

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Dragger } = Upload;

const props = {
  style: {
    padding: 16,
    height: "100%",
    width: "100%",
  },
  name: "file",
  multiple: false,
  showUploadList: false,
  customRequest: () => {},
};
const layout = {
  // labelCol: {
  //   span: 4,
  // },
  // wrapperCol: {
  //   span: 20,
  // },
};

function MainPage() {
  const [form] = Form.useForm();
  const [xsltFiles, setXsltFiles] = useState();
  const [xmlObj, setXMLObj] = useState();
  const [customXsltFiles, setCustomXsltFiles] = useState();
  const [transformedFiles, setTransformedFiles] = useState();

  useEffect(() => {
    const transformedFilesData = localStorage.getItem("transformedFiles");
    if (transformedFilesData) {
      setTransformedFiles(JSON.parse(transformedFilesData));
    }

    fetch(window.location.href + "api/v1/storage/xslt")
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(
        (files) => {
          setXsltFiles(files);
          if (files.length > 0) {
            form.setFieldsValue({ xsltName: files[0].name });
          }
        },
        (error) => {
          notification["error"]({
            message: "Failed to retrieve XSLT",
            description: error.toString(),
          });
        }
      );
  }, []);

  const onXSLTUpload = async (file) => {
    let base64FileContent = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () =>
        resolve(Base64.fromUint8Array(new Uint8Array(reader.result)));
    });

    var uploadedXsltFiles = customXsltFiles ? customXsltFiles : [];
    var uploadedXsltFiles = uploadedXsltFiles.filter(
      (item) => item.name !== file.name
    );
    uploadedXsltFiles.push({
      data: base64FileContent,
      name: file.name,
    });

    setCustomXsltFiles(uploadedXsltFiles);
    form.setFieldsValue({ xsltName: file.name });
  };

  const onXMLUpload = async (file) => {
    let base64FileContent = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () =>
        resolve(Base64.fromUint8Array(new Uint8Array(reader.result)));
    });
    setXMLObj({ name: file.name, data: base64FileContent });
    form.submit();
  };

  const onFinish = (values) => {
    var xsltFile = null;
    if (xsltFiles) {
      xsltFile = xsltFiles.find((file) => file.name === values.xsltName);
    }

    if (customXsltFiles && !xsltFile) {
      xsltFile = customXsltFiles.find((file) => file.name === values.xsltName);
    }

    if (!xsltFile) {
      return notification["error"]({
        message: "XSLT File Error",
        description: "Failed to find XSLT file in cache",
      });
    }

    fetch(window.location.href + "transform", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        xml: xmlObj.data,
        xslt: xsltFile.data,
      }),
    })
      .then(async (res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        var obj = await res.json();
        if (!obj.success) {
          console.log("ERROR: ", obj);
          throw Error(obj.message);
        }
        return obj;
      })
      .then(
        (res) => {
          var id = uuidv4();
          var transformedFilesData = localStorage.getItem("transformedFiles");

          var transformedFile = {
            id: id,
            date: new Date().toLocaleString(),
            xsltName: values.xsltName,
            xmlName: xmlObj.name,
            html: {
              name: "transformed." + xmlObj.name + ".html",
              data: res.data,
            },
          };

          var transformedFiles;
          if (transformedFilesData == null) {
            transformedFiles = [transformedFile];
          } else {
            transformedFiles = JSON.parse(transformedFilesData);
            transformedFiles.push(transformedFile);
          }

          localStorage.setItem(
            "transformedFiles",
            JSON.stringify(transformedFiles)
          );
          setTransformedFiles(transformedFiles);
        },
        (err) => {
          notification["error"]({
            message: "Failed to transform XSLT",
            description: err.toString(),
          });
        }
      );
  };

  const onDownloadFile = async (filename, data) => {
    const decodedHTML = Base64.decode(data);
    const blob = new Blob([decodedHTML], { type: "application/html" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onOpenInNewPage = async (id) => {
    window.open(window.location.href + "render/" + id);
  };

  const renderXSLTSelection = () => {
    var options = [];

    if (customXsltFiles) {
      customXsltFiles.forEach((file) =>
        options.push(
          <Option key={file.name} value={file.name}>
            <Typography.Text type="danger">Custom | </Typography.Text>{" "}
            {file.name}
          </Option>
        )
      );
    }

    if (xsltFiles) {
      xsltFiles.forEach((file) =>
        options.push(
          <Option key={file.name} value={file.name}>
            {file.name}
          </Option>
        )
      );
    }

    return (
      <Form.Item label="XSLT" name="xsltName" style={{ width: "100%" }}>
        <Select
          loading={xsltFiles == null}
          label="XSLT: "
          style={{ width: "100%" }}
        >
          {options.map((opt) => opt)}
        </Select>
      </Form.Item>
    );
  };

  const columns = [
    {
      title: "XSLT",
      dataIndex: "xsltName",
      key: "xsltName",
    },
    {
      title: "XML",
      dataIndex: "xmlName",
      key: "xmlName",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (dateText) => new Date(dateText).toLocaleString(),
      sorter: (a, b) => new Date(a.date) > new Date(b.date),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: ["descend"],
    },
    {
      title: "Operations",
      align: "center",
      width: 100,
      render: (obj) => {
        console.log("DATA: ", obj);
        return (
          <Space>
            <Tooltip title="Download HTML">
              <Button
                type="dashed"
                size="middle"
                onClick={() => onDownloadFile(obj.html.name, obj.html.data)}
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Open rendered HTML in a new page">
              <Button
                type="dashed"
                size="middle"
                onClick={() => onOpenInNewPage(obj.id, obj.html.data)}
              >
                <FileOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const clearTransformedDocumentsCache = () => {
    localStorage.removeItem("transformedFiles");
    setTransformedFiles(null);
  };

  const renderRecentTransformationsCard = (dataSource) => {
    if (!dataSource) {
      return;
    }
    return (
      <div style={{ margin: "32px 0 0 0 " }}>
        {/* <Divider dashed title={"hey"}>Hey</Divider> */}

        <Divider plain style={{ color: "#f0f0f0" }}>
          <Typography.Text type="secondary">
            Recent Transformations
          </Typography.Text>
        </Divider>
        <Tooltip title="Click to clear all transformed documents from browser cache.">
          <Button
            size="middle"
            onClick={() => clearTransformedDocumentsCache()}
          >
            Clear
          </Button>
        </Tooltip>
        <Table
          style={{ margin: "8px 0" }}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 2 }}
          size="small"
        />
      </div>
    );
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header className="header">
        <Typography.Title level={4} style={{ color: "#fff", margin: 0 }}>
          XSLT 2.0 Transformer
        </Typography.Title>
      </Header>
      <Content
        style={{
          padding: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Layout
          className="site-layout-background"
          style={{
            padding: "24px",
            display: "flex",
            maxWidth: "800px",
          }}
        >
          <Content
            style={{
              padding: "24px 24px",
            }}
          >
            <Form {...layout} name="basic" form={form} onFinish={onFinish}>
              <Row gutter={16}>
                <Col flex={2}>{renderXSLTSelection()}</Col>
                <Col>
                  <Upload
                    multiple={false}
                    showUploadList={false}
                    customRequest={() => {}}
                    beforeUpload={onXSLTUpload}
                  >
                    <Tooltip title="Uploads custom XSLT that would be stored in browser cache.">
                      <Button type="dashed" icon={<UploadOutlined />}>
                        Upload Custom
                      </Button>
                    </Tooltip>
                  </Upload>
                </Col>
              </Row>
              <Dragger {...props} beforeUpload={onXMLUpload}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to transform XML
                </p>
                <p className="ant-upload-hint">
                  Supports only a single file upload.
                </p>
              </Dragger>
            </Form>
            {renderRecentTransformationsCard(transformedFiles)}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        XSLT 2.0 Transformer ©2022 Created by Systems integration solutions, UAB
      </Footer>
    </Layout>
  );
}

export default MainPage;
