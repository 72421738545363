import React from "react";
import { useParams } from "react-router";
import { Base64 } from "js-base64";

export const RenderPage = () => {
  const { documentId } = useParams();
  var transformedFiles = JSON.parse(localStorage.getItem("transformedFiles"));
  var file = transformedFiles.find((item) => item.id === documentId);

  if (file) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: Base64.decode(file.html.data) }}
      />
    );
  }

  return <div>Not Found</div>;
};
